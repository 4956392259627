<template>
  <div></div>
</template>

<script>
import { Toast } from 'vant'
import { getUserInfoByUserName } from '@/api/wx.js'
export default {
  data() {
    return {}
  },
  mounted() {
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration: 0
    })
    localStorage.setItem('url', window.V3url + '/weixinlocal/index.html')
    sessionStorage.setItem('url', window.V3url + '/weixinlocal/index.html')
    this.getUserInfo()
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      getUserInfoByUserName({ username: this.$route.query.username }).then(
        res => {
          if (sessionStorage.getItem('token') == this.$route.query.key) {
            localStorage.setItem('userInfo', JSON.stringify(res.data[0]))
            sessionStorage.setItem('userInfo', JSON.stringify(res.data[0]))
            localStorage.setItem('v8_login_pwd', res.data[0].pwd)
            localStorage.setItem('v8_login_username', res.data[0].username)
            Toast.clear()
            this.goto()
          } else {
            Toast('参数错误')
          }
        }
      )
    },
    // 跳转
    goto() {
      this.$router.replace('/faceCollection')
    }
  }
}
</script>

<style></style>
